@import '../../variables.scss';

main {
    flex-grow: 1;
    padding: 2rem;

    section {
        margin-bottom: 2rem;
    }

    h1,
    h2 {
        color: $secondary-color;
        margin-bottom: 1rem;
    }

    .hero {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $secondary-color;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

        .hero-content {
            flex: 1.5;
            padding: 3rem;
            color: white;

            h1 {
                font-size: 2.5rem;
                color: $primary-color;
                margin-bottom: 1.5rem;
                text-transform: uppercase;
                letter-spacing: 1px;
            }

            p {
                font-size: 1.1rem;
                line-height: 1.6;
                margin-bottom: 2rem;
            }

            .cta-button {
                font-size: 1.1rem;
                padding: 1rem 2rem;
                border-radius: 50px;
                text-transform: uppercase;
                letter-spacing: 1px;
                transition: all 0.3s ease;

                &:hover {
                    background-color: white;
                    color: $primary-color;
                    transform: translateY(-3px);
                    box-shadow: 0 5px 15px rgba(82, 178, 71, 0.3);
                }
            }
        }

        .hero-image {
            flex: 1;
            padding: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
                max-height: 400px;
                object-fit: contain;
                border-radius: 10px;
            }
        }
    }

    .cta-button {
        display: inline-block;
        background-color: $primary-color;
        color: white;
        padding: 0.75rem 1.5rem;
        border-radius: 5px;
        text-decoration: none;
        font-weight: 500;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: darken($primary-color, 10%);
        }
    }
}