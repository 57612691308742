@import '../../variables.scss';


.cta {
    background-color: $secondary-color;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 4rem;
    margin: 2rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
    }

    .container {
        position: relative;
        z-index: 1;
        padding: 4rem 2rem;
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cta-content {
        flex: 0 0 60%;
        max-width: 60%;

        h2 {
            font-size: 2.5rem;
            color: $primary-color;
            margin-bottom: 1rem;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        p {
            color: white;
            font-size: 1.1rem;
            line-height: 1.6;
        }
    }

    .cta-button-container {
        flex: 0 0 35%;
        max-width: 35%;
        text-align: right;
    }

    .cta-button.outline {
        display: inline-block;
        color: white;
        background-color: transparent;
        border: 2px solid white;
        padding: 0.75rem 1.5rem;
        border-radius: 50px;
        text-decoration: none;
        font-weight: 500;
        transition: all 0.3s ease;
        text-transform: uppercase;
        letter-spacing: 1px;

        &:hover {
            background-color: white;
            color: $secondary-color;
        }
    }
}

// Responsive design
@media (max-width: 992px) {
    .sec-features .row {
        grid-template-columns: repeat(2, 1fr);
    }

    .solution {
        .row {
            flex-direction: column;
        }

        .solution-image {
            margin-bottom: 2rem;
        }
    }
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;

        .hero-content,
        .hero-image {
            padding: 2rem;
        }
    }

    .sec-features {
        .container {
            padding: 2rem;
        }

        h2 {
            font-size: 2rem;
        }

        .row {
            grid-template-columns: 1fr;
        }

        .feature {
            padding: 1.25rem;

            .icon {
                font-size: 2rem;
            }

            h3 {
                font-size: 1.1rem;
            }

            p {
                font-size: 0.9rem;
            }
        }
    }

    .solution {
        .container {
            padding: 2rem;
        }

        h2 {
            font-size: 2rem;
        }

        .solution-item {
            .icon {
                font-size: 2rem;
            }

            .content {
                h3 {
                    font-size: 1.1rem;
                }

                ul li {
                    font-size: 0.9rem;
                }
            }
        }
    }

    .cta {
        .container {
            padding: 3rem 1.5rem;
        }

        .row {
            flex-direction: column;
        }

        .cta-content,
        .cta-button-container {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
        }

        .cta-content {
            margin-bottom: 2rem;

            h2 {
                font-size: 2rem;
            }

            p {
                font-size: 1rem;
            }
        }
    }
}