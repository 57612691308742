@import '../../variables.scss';

.partners {
    padding: 4rem 0;
    background-color: $background-color;

    .partners-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 15px;
        text-align: center;
    }

    .logo-wrapper {
        margin-bottom: 2rem;
    }

    .logo-row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: -15px; // Negative margin to counteract the padding on logo items for alignment

        .logo-item {
            flex: 0 0 calc(33.333% - 30px); // Adjust width for three items minus margin/padding
            max-width: calc(33.333% - 30px);
            padding: 15px;
            box-sizing: border-box;

            @media (max-width: 768px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 1rem;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            .logo {
                max-width: 100%;
                height: auto;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}