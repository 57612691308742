@import '../../variables.scss';

// Solution section styles
.solution {
    background-color: $secondary-color;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 4rem;
    margin: 2rem;

    .container {
        padding: 3rem;
        color: white;
    }

    .row {
        display: flex;
        flex-direction: row-reverse;
        gap: 2rem;
    }

    .solution-image {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 400px;
            object-fit: contain;
            border-radius: 10px;
        }
    }

    .solution-content {
        flex: 1;

        h2 {
            font-size: 2.5rem;
            color: $primary-color;
            margin-bottom: 2rem;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    .solution-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2rem;

        .icon {
            font-size: 2.5rem;
            color: $primary-color;
            margin-right: 1rem;
            flex-shrink: 0;
        }

        .content {
            h3 {
                color: white;
                font-size: 1.2rem;
                margin-bottom: 0.75rem;
            }

            ul {
                list-style-type: none;
                padding-left: 0;

                li {
                    color: rgba(255, 255, 255, 0.8);
                    font-size: 1rem;
                    line-height: 1.4;
                    margin-bottom: 0.5rem;

                    &:before {
                        content: "•";
                        color: $primary-color;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }
    }
}