@import '../../variables.scss';

.info-request-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: $secondary-color;
    margin: 2rem;
    border-radius: 15px;

    .info-request-button {
        padding: 1rem 2rem;
        background-color: $primary-color;
        color: white;
        border: none;
        border-radius: 50px;
        font-size: 1.1rem;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: all 0.3s ease;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        &:hover {
            background-color: darken($primary-color, 10%);
            transform: translateY(-2px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }
    }
}