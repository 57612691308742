@import '../../variables.scss';

.problem {
    padding: 2rem;
}

.p1 {
    text-align: center;
    padding: 0.2rem;
}

.sec-features {
    background-color: $secondary-color;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    margin: 2rem;

    .container {
        padding: 2rem;
        color: white;
    }

    h2 {
        font-size: 2.5rem;
        color: $primary-color;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
    }

    .row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    .feature {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding: 1.5rem;
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.15);
        }

        .icon {
            font-size: 2.5rem;
            color: $primary-color;
            margin-bottom: 1rem;
        }

        h3 {
            color: white;
            font-size: 1.2rem;
            margin-bottom: 0.75rem;
        }

        p {
            color: rgba(255, 255, 255, 0.8);
            font-size: 1rem;
            line-height: 1.4;
        }
    }
}