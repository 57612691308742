@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@import './variables.scss';

@import './components/Header/Header';
@import './components/Home/Home';
@import './components/Problem/Problem';
@import './components/Solution/Solution';
@import './components/Contact/Contact';
@import './components/Team/Team';
@import './components/Partners/Partners';
@import './components/Footer/Footer';

// Variables
$primary-color: #52B247;
$secondary-color: #202332;
$background-color: #F6F6F6;
$text-color: #333;

// Global styles
body {
  font-family: 'DM Sans', sans-serif;
  color: $text-color;
  background-color: $background-color;
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

