@import '../../variables.scss';

// Team section styles
.team {
    padding: 4rem 0;
    background-color: $background-color;

    .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .team-intro {
        text-align: center;
        max-width: 800px;
        margin: 0 auto 3rem;

        h2 {
            font-size: 2.5rem;
            color: $primary-color;
            margin-bottom: 1rem;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        p {
            font-size: 1.1rem;
            line-height: 1.6;
            color: $text-color;
        }
    }

    .team-members {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;
    }

    .team-member {
        text-align: center;
        flex: 0 0 calc(33.333% - 2rem);
        max-width: calc(33.333% - 2rem);

        .team-member-image {
            width: 150px; // Base size for desktop
            max-width: 50%; // Ensures it doesn't grow too large
            border-radius: 50%;
            margin-bottom: 1rem;
            transition: all 0.3s ease; // Smooth transition for responsive changes
        }

        h5 {
            font-size: 1.2rem;
            color: $secondary-color;
            margin-bottom: 0.5rem;
        }

        p {
            font-size: 1rem;
            color: $text-color;
        }
    }
}

// Responsive design for team section
@media (max-width: 992px) {
    .team {
        .team-member {
            flex: 0 0 calc(50% - 2rem);
            max-width: calc(50% - 2rem);
        }
    }
}

@media (max-width: 768px) {
    .team {
        padding: 3rem 0;

        .team-intro {
            h2 {
                font-size: 2rem;
            }

            p {
                font-size: 1rem;
            }
        }

        .team-members {
            flex-direction: column;
            align-items: center;
        }

        .team-member {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 2rem;

            .team-member-image {
                width: 120px; // Smaller size for mobile
                max-width: 80%; // Allows for some flexibility
            }
        }
    }
}