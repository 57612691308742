@import '../../variables.scss';

.App-header {
    background-color: $secondary-color;
    color: white;
    width: 100%;
}

.site-nav {
    .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px;
    }

    .row {
        display: flex;
        flex-direction: column;
        align-items: flex-start; // Align items to the start

        @media (min-width: 993px) {
            flex-direction: row;
            align-items: center; // Center align on desktop
            justify-content: space-between;
        }
    }

    .col {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (min-width: 993px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    .logo {
        img {
            max-height: 40px;
            margin-bottom: 10px;

            @media (min-width: 993px) {
                margin-bottom: 0;
            }
        }
    }

    .burger-menu {
        display: block;
        background: none;
        border: none;
        font-size: 1.5rem;
        color: white;
        cursor: pointer;
        padding: 0.5rem;
        position: absolute;
        right: 15px; // Match container's padding
        top: 15px; // Align with top padding

        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px $primary-color;
        }

        @media (min-width: 993px) {
            display: none;
        }
    }

    .site-menu {
        display: none; // Hidden by default on mobile
        list-style: none;
        padding: 0;
        margin: 0 0 10px 0;

        &.open {
            display: block;
        }

        li {
            a {
                color: white;
                text-decoration: none;
                padding: 0.5rem 0.8rem;
                display: block;
                transition: color 0.3s ease;

                &:hover,
                &.active {
                    color: $primary-color;
                }
            }
        }

        @media (min-width: 993px) {
            display: flex;
            flex-direction: row;
            margin: 0;
        }
    }

    .call-us {
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none;

        svg {
            margin-right: 0.5rem;
        }
    }
}