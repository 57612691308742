@import '../../variables.scss';

.footer {
    background-color: $secondary-color;
    color: white;
    padding: 2rem 0;
    font-size: 0.9rem;

    .footer-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 1rem;
    }

    .footer-section {
        flex: 1;
        min-width: 200px;
        margin: 1rem;
        text-align: center;

        h3 {
            color: $primary-color;
            margin-bottom: 1rem;
            font-size: 1.2rem;
        }

        p,
        address {
            color: rgba(255, 255, 255, 0.8);
        }

        ul,
        address {
            list-style: none;
            padding: 0;

            a {
                color: rgba(255, 255, 255, 0.8);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                    color: $primary-color;
                }
            }
        }
    }

    .copyright {
        text-align: center;
        margin-top: 2rem;
        padding-top: 1rem;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
}